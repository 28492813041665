<template  v-if="lang && __(title)">
  <section
    class="vertical-logo"
    :id="__(title).id"
    :class="[
      __(title).reverse == true ? 'reverse' : '',
      __(title).dark == true ? 'dark' : '',
    ]"
  >
    <div class="paralax-item shape-circle">
      <div class="paralax-item-inner top speed-5">
        <svg class="shape">
          <use xlink:href="../../assets/images/icon/shape-sprite.svg#circle" />
        </svg>
      </div>
    </div>
    <div class="paralax-item shape-triangle">
      <div class="paralax-item-inner  spin speed-6">
        <svg class="shape">
          <use
            xlink:href="../../assets/images/icon/shape-sprite.svg#triangle"
          />
        </svg>
      </div>
    </div>
    <div class="section-wrap">
      <div class="container">
        <div class="row">
          <div class="section-title cntr">
            <h2>{{ __(title).title }}</h2>
            <h3>
              {{ __(title).subTitle }}
            </h3>
          </div>
        </div>
        <div
          class="
            d-flex
            justify-content-center
            align-items-center
            vertical-logo-list
          "
        >
          <div
            class="vertical-logo-item"
            v-for="listitem in __(title).list"
            :key="listitem.id"
            :class="[__(title).animated == true ? 'animated' : '']"
          >
            <img
              :src="listitem.image"
              alt=""
            />
          </div>
        </div>

        <div class="section-bottom d-flex justify-content-center">
          <a :href="__(title).link" class="bttn link primary"
            >{{__(general).other.viewAll}}
            <svg class="shape">
              <use
                xlink:href="../../assets/images/icon/icon-sprite.svg#right-arrow"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

    <script>
// Import Swiper Vue.js components
import tr from "@/lang/tr.js";
import en from "@/lang/en.js";
export default {
    mixins: [tr, en],
  name: "verticalLogoComponent",
  props: {
    lang:String,
    title:String,
    general:String
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="./verticalLogo.scss" lang="scss" scoped>
</style>
