<template>
  <main>
    <landingPage :lang="lang" :title="'pages.landingPage'" general="general"></landingPage>
    <pricesTableComponent :lang="lang" :title="'pages.prices'" general="general"></pricesTableComponent>
    <faqSections :lang="lang" :title="'pages.prices.faq'" general="general"></faqSections>
  </main>
</template>
<script>

import pricesTableComponent from "@/components/pricesTable/pricesTable.vue";
import faqSections from "@/components/faqSection/faqSection.vue";
import landingPage from "@/components/landingPage/landingPage.vue";
import tr from "@/lang/tr.js";
import en from "@/lang/en.js";
export default {
  mixins: [tr, en],

  metaInfo() {
    return {
      title: this.metaTitle
        ? this.metaTitle
        : "KOBİSİ HIZLI E-TİCARET PLATFORMU",
      meta: [
        {
          name: "description",
          content: this.metaDescription ? this.metaDescription : "",
        },
        {
          property: "og:title",
          content: this.metaTitle ? this.metaTitle : "",
        },
        { name: "robots", content: "index,follow" },
        { property: "og:type", content: "" },
        { property: "og:title", content: " Ücretsiz Kullanmaya Başla" },
        { property: "og:description", content: "Ücret Yok. Kredi Kartı Bilgisi Gerekmez. Risk Yok. Kendi e-ticaret sitenizde online satış yapmak hiç bu kadar kolay, hızlı ve ulaşılabilir olmamıştı." },
        { property: "og:image", content: "" },
        { property: "og:url", content: "lp-kobisi-eticaret-paketleri" },
        { property: "og:site_name", content: "Kobisi" },
      ],
    };
  },
  name: "LandingPageView",
  components: {
    pricesTableComponent,
    faqSections,
    landingPage
  },

  data() {
    return {

      metaTitle: null,
      metaDescription: null
    }
  },
  props: {
    lang: String,
    title: String,
    general: String,
  },
  mounted() {
    document.body.classList.add("landing", "active-page");
    this.metaTitle = this[this.lang].pages.landingPage.metaTags.title;
    this.metaDescription = this[this.lang].pages.landingPage.metaTags.description;
  },
  destroyed() {
    document.body.classList.remove("landing", "active-page");
  },
};
</script>
<style>

</style>