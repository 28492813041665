<template>
  <pageNotFoundComponent :lang="lang" :title="'pages.notFoundPage'" general="general"></pageNotFoundComponent>
</template>
<script>

import pageNotFoundComponent from '@/components/pageNotFound/pageNotFound.vue'
import tr from "@/lang/tr.js";
import en from "@/lang/en.js";
export default {
  mixins: [tr, en],

  metaInfo() {
    return {
      title: this.metaTitle ? this.metaTitle : 'KOBİSİ HIZLI E-TİCARET PLATFORMU',
      meta: [
        {
          name: "description",
          content:
            "The page you are looking for is no longer available or its link may have changed. Please check the page link and try again later, or you can return to the main page using the button below",
        },
        {
          property: "og:title",
          content: this.metaTitle ? this.metaTitle : '',
        },
        { property: "og:site_name", content: "Epiloge" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
  name: "PageNotFoundView",
  components: {
    pageNotFoundComponent

  },
  data() {
    return {
      metaTitle: null
    }
  },
  props: {
    lang: String,
    title: String,
    general: String,
  },
  mounted() {
    document.body.classList.add("notFound", "active-page");
    this.metaTitle = this[this.lang].pages.prices.title
  },
  destroyed() {
    document.body.classList.remove("notFound", "active-page");
  },
};
</script>
<style >

</style>