<template  v-if="lang && __(title)">
  <section
    :id="__(title).id ? __(title).id : ''"
    class="image-text"
    :class="[
      __(title).reverse && __(title).reverse == true ? 'reverse' : '',
      __(title).dark && __(title).dark == true ? 'dark' : '',
    ]"
  >
    <div class="paralax-item shape-dotes">
      <div class="paralax-item-inner top speed-5">
        <svg class="shape">
          <use xlink:href="../../assets/images/icon/shape-sprite.svg#dotes" />
        </svg>
      </div>
    </div>

    <div class="section-wrap">
      <div class="container">
        <div
          class="inner-wrapper row row-cols-1 row-cols-sm-2 align-items-center"
        >
          <div
            class="section-image"
            :class="[__(title).animated && __(title).animated == true ? 'animated' : '']"
          >
            <img v-bind:src="__(title).image ? __(title).image : ''" alt="" />
          </div>
          <div
            class="section-text"
            v-bind:class="__(title).animated ? 'animated' : ''"
          >
            <div class="inner-wrapper">
              <h2 v-if="lang && __(title)">{{ __(title).title }}</h2>
              <p>{{ __(title).text ? __(title).text : "" }}</p>
              <a :href="__(title).link" class="bttn primary radiused">{{
                __(title).button ? __(title).button : ""
              }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// Import Swiper Vue.js components
import tr from "@/lang/tr.js";
import en from "@/lang/en.js";
export default {
  mixins: [tr, en],
  name: "imageTextComponent",
  props: {
    lang: String,
    title: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="./imageText.scss" lang="scss" scoped>
</style>
