<template>
  <main>
    <pageInnerHeaderComponent
      :lang="lang"
      :title="'pages.features'"
    ></pageInnerHeaderComponent>
    <generalPreferencesComponent
      :lang="lang"
      :title="'pages.features'"
    ></generalPreferencesComponent>
  </main>
</template>
<script>
import pageInnerHeaderComponent from "@/components/pageInnerHeader/pageInnerHeader.vue";
import generalPreferencesComponent from "@/components/generalPreferences/generalPreferences.vue";
import tr from "@/lang/tr.js";
import en from "@/lang/en.js";
export default {
  mixins: [tr, en],
  name: "PreferencesView",
  components: {
    pageInnerHeaderComponent,
    generalPreferencesComponent,
  },
  metaInfo() {
    return {
      title: this.metaTitle
        ? this.metaTitle
        : "KOBİSİ HIZLI E-TİCARET PLATFORMU",
      meta: [
        {
          name: "description",
          content: this.metaDescription ? this.metaDescription : "",
        },
        {
          property: "og:title",
          content: this.metaTitle ? this.metaTitle : "KOBİSİ HIZLI E-TİCARET PLATFORMU",
        },
        { name: "robots", content: "index,follow" },
        { property: "og:type", content: " MAKALE (MAKALE) " },
        { property: "og:title", content: " SAYFANIN BAŞLIĞI " },
        { property: "og:description", content: " İÇERİK AÇIKLAMASI " },
        { property: "og:image", content: " GÖRÜNTÜNÜN LİNKİ " },
        { property: "og:url", content: " PERMALINK " },
        { property: "og:site_name", content: " SİTE ADI " },
      ],
    };
  },
  data() {
    return {
      metaDescription: null,
      metaTitle: null,
    };
  },
  props: {
    lang: String,
    title: String,
  },
  beforeUpdate() {
    this.metaTitle = this[this.lang].pages.features.title;
    this.metaDescription = this[this.lang].pages.features.description;
  },
  mounted() {
    document.body.classList.add("features", "active-page");
    this.metaTitle = this[this.lang].pages.features.title;
    this.metaDescription = this[this.lang].pages.features.description;
  },
  destroyed() {
    document.body.classList.remove("features", "active-page");
  },
};
</script>
<style>
</style>