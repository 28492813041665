<template>
  <div>
    <section class="page-inner-header">
      <div class="container">
        <div class="paralax-item shape-dotes">
          <div class="paralax-item-inner bottom speed-25">
            <svg class="shape">
              <use
                xlink:href="../../assets/images/icon/shape-sprite.svg#dotes"
              />
            </svg>
          </div>
        </div>
        <div class="paralax-item shape-triangle">
          <div class="paralax-item-inner spin bottom speed-20">
            <svg class="shape">
              <use
                xlink:href="../../assets/images/icon/shape-sprite.svg#triangle"
              />
            </svg>
          </div>
        </div>
        <div class="paralax-item shape-plus">
          <div class="paralax-item-inner bottom speed-3">
            <svg class="shape">
              <use
                xlink:href="../../assets/images/icon/shape-sprite.svg#plus"
              />
            </svg>
          </div>
        </div>
        <div class="row align-items-center row-cols-1 row-cols-sm-2">
          <div class="page-title paralax-item-inner bottom speed-15">
            <h1 v-if="IntegrationItem">
              {{ IntegrationItem.title }}
            </h1>
            <h2 v-if="IntegrationItem">
              {{ IntegrationItem.description }}
            </h2>
            <router-link
              class="bttn primary radiused"
              to="/lp-kobisi-eticaret-paketleri"
            >
              {{ __(general).integrationsPage.startTryButton }}</router-link
            >
          </div>
          <div
            class="page-banner paralax-item-inner bottom speed-15"
            v-if="IntegrationItem"
          >
            <img :src="IntegrationItem.logo" alt="" />
          </div>
        </div>
      </div>
    </section>
    <section class="cms-section">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-7">
            <template v-if="IntegrationItem">
              <div v-html="IntegrationItem.content" class="cms-text">
                
              </div>
            </template>
          </div>
          <div class="col-12 col-md-4 offset-md-1">
            <div class="csm-side">
              <h3>{{__(general).integrationsPage.helperLinks}}</h3>
              <ul>
                <li>
                  <router-link to="/entegrasyonlar">
                    {{__(general).integrationsPage.viewAllIntegration}}
                    <span>
                    {{__(general).integrationsPage.viewAllIntegrationText}}
                      </span
                    >
                  </router-link>
                </li>
                <!-- <li>
                  <router-link to="">
                    {{__(general).integrationsPage.howdo}}
                    <span>
                    {{__(general).integrationsPage.howdoText}}
                     
                    </span>
                  </router-link>
                </li> -->
                <li>
                  <a href="javascript:$zopim.livechat.window.show();">
                    {{__(general).integrationsPage.contuctUs}}
                    <span>
                    {{__(general).integrationsPage.contuctUsText}}
                    </span
                    >
                    </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="block-content">
      <div class="container">
        <div class="inner-wrap">
          <div class="first-area">
            <h2>
              {{ __(general).integrationsPage.bottomTitle }}
            </h2>
            <p>
               {{ __(general).integrationsPage.bottomText }}
            </p>
          </div>
          <div class="second-area">
            <router-link
              class="bttn invert primary radiused"
              to="/entegrasyonlar"
            >
              {{ __(general).integrationsPage.viewAllIntegration }}</router-link
            >
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

  <script>
// Import Swiper Vue.js components

import tr from "@/lang/tr.js";
import en from "@/lang/en.js";

export default {
  mixins: [tr, en],
  name: "integrationListItemComponent",
  props: {
    lang: String,
    IntegrationItem: Object,
    general: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="./integrationListItem.scss" lang="scss" scoped>
</style>
