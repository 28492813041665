<template>
  <main>
    <!-- <countdownPopup :lang="lang" :title="'popupCountdown'" /> -->

    <mastheadComponent :lang="lang" general="general" :title="'pages.home.masthead'" />
    <showcaseComponent :lang="lang" general="general" :title="'pages.home.themes'" />
    <horizontalInfographicComponent :lang="lang" general="general" :title="'pages.home.adventages'" />
    <imageTextComponent :lang="lang" general="general" :title="'pages.home.designWizard'" />
    <verticalLogoComponent :lang="lang" general="general" :title="'pages.home.integrations'" />
    <imageTextComponent :lang="lang" general="general" :title="'pages.home.orderManagement'" />
    <verticalInfographicComponent :lang="lang" general="general" :title="'pages.home.storePreferences'" />
    <verticalLogoComponent :lang="lang" general="general" :title="'pages.home.customers'" />
    <recommendationComponent :lang="lang" general="general" :title="'pages.home.recomendation'" />
    <callToActionComponent :lang="lang" general="general" :title="'pages.home.callToAction'" />


  </main>
</template>
<script>
// import countdownPopup from "@/components/countdownPopup/countdownPopup.vue";
import mastheadComponent from "@/components/masthead/masthead.vue";
import showcaseComponent from "@/components/showcase/showcase.vue";
import horizontalInfographicComponent from "@/components/horizontalInfographic/horizontalInfographic.vue";
import imageTextComponent from "@/components/imageText/imageText.vue";
import verticalLogoComponent from "@/components/verticalLogo/verticalLogo.vue";
import verticalInfographicComponent from "@/components/verticalInfographic/verticalInfographic.vue";
import recommendationComponent from "@/components/recommendation/recommendation.vue";
import callToActionComponent from "@/components/callToAction/callToAction.vue";
import tr from "@/lang/tr.js";
import en from "@/lang/en.js";



export default {
  metaInfo() {
    return {
      title: this.metaTitle ? this.metaTitle : "KOBİSİ HIZLI E-TİCARET PLATFORMU",
      meta: [
        {
          name: "description",
          content: this.metaDescription ? this.metaDescription : "",
        },
        {
          property: "og:title",
          content: this.metaTitle ? this.metaTitle : "",
        },
        { name: "robots", content: "index,follow" },
        { property: "og:type", content: "" },
        { property: "og:title", content: "İşinizi kolaylaştıracak tüm entegrasyonlar!" },
        { property: "og:description", content: "Kobisi mağazanızın yanında ürünlerinizi pazaryerlerine entegre ederek pazardaki payınızı büyütün." },
        { property: "og:image", content: "" },
        { property: "og:url", content: "kobisi.com" },
        { property: "og:site_name", content: "Kobisi" },
      ],
    };
  },
  mixins: [tr, en],
  name: "IndexView",
  components: {
    mastheadComponent,
    showcaseComponent,
    horizontalInfographicComponent,
    imageTextComponent,
    verticalLogoComponent,
    verticalInfographicComponent,
    recommendationComponent,
    callToActionComponent,

    // countdownPopup
  },

  data() {
    return {
      metaDescription: null,
      metaTitle: null,
    };
  },

  props: {
    lang: String,
    general: String
  },
  mounted() {
    document.body.classList.add("home", "active-page");
    this.metaTitle = this[this.lang].pages.home.title;
    this.metaDescription = this[this.lang].pages.home.description;
    // let a = this[this.lang].pages.home.title;
    // window.addEventListener("blur", function () {
    //   document.title = "bizi unutmayın"
    // });

    // window.addEventListener("focus", function () {
    //   document.title = a
    // });

  },
  destroyed() {
    document.body.classList.remove("home", "active-page");
  },
  created() {
    // this.home = JSON.parse(sessionStorage.getItem("data"));
    //   fetch("https://mocki.io/v1/8445c46c-122c-4619-af52-0a0516c66f0e")
    //     .then((response) => response.json())
    //     .then((data) => (this.home = data));
    //   Simple GET request using fetch
  },
};
</script>

