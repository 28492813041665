<template>
  <section
    :id="__(title).id"
    :class="[__(title).reverse == true ? 'reverse' : '']"
    class="call-to-action"
  >
    <div class="container">
      <div class="call-to-action-area">
        <div class="inner-wrapper call-to-action-text">
          <h2>{{ __(title).title }}</h2>
          <h3>{{ __(title).subTitle }}</h3>
        </div>
        <div class="inner-wrapper">
          <a
            :href="__(title).href"
            :class="'bttn invert primary radiused'"
            target="_blank"
          >
            {{ __(title).button }}
        </a>
        </div>
      </div>
    </div>
  </section>
</template>

    <script>
import tr from "@/lang/tr.js";
import en from "@/lang/en.js";
export default {
  mixins: [tr, en],
  name: "callToActionComponent",
  props: {
    lang: String,
    title: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="./callToAction.scss" lang="scss" scoped>
</style>
