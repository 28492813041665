<template>
  <main>
    <pricesTableComponent :lang="lang" :title="'pages.prices'" general="general"></pricesTableComponent>
    <faqSections :lang="lang" :title="'pages.prices.faq'" general="general"></faqSections>
  </main>
</template>
<script>

import pricesTableComponent from "@/components/pricesTable/pricesTable.vue";
import faqSections from "@/components/faqSection/faqSection.vue";
import tr from "@/lang/tr.js";
import en from "@/lang/en.js";
export default {
  mixins: [tr, en],

  metaInfo() {
    return {
      title: this.metaTitle
        ? this.metaTitle
        : "KOBİSİ HIZLI E-TİCARET PLATFORMU",
      meta: [
        {
          name: "description",
          content: this.metaDescription ? this.metaDescription : "",
        },
        {
          property: "og:title",
          content: this.metaTitle ? this.metaTitle : "KOBİSİ HIZLI E-TİCARET PLATFORMU",
        },
        { name: "robots", content: "index,follow" },
        { property: "og:type", content: "" },
        { property: "og:title", content: " Paketinizi seçerek mağazanizi oluşturmaya başlayin!" },
        { property: "og:description", content: "Seçtiğin paketi dilediğin zaman değiştirebilir veya iptal edebilirsin." },
        { property: "og:image", content: "" },
        { property: "og:url", content: "kobisi.com/fiyatlar" },
        { property: "og:site_name", content: "Kobisi" },
      ],
    };
  },
  name: "PricesView",
  components: {
    pricesTableComponent,
    faqSections,
  },
  data() {
    return {
      metaTitle: null,
      metaDescription: null
    }
  },
  props: {
    lang: String,
    title: String,
    general: String,
  },
  mounted() {
    document.body.classList.add("prices", "active-page");
    this.metaTitle = this[this.lang].pages.prices.metaTags.title;
    this.metaDescription = this[this.lang].pages.prices.metaTags.description;


    // let a = this[this.lang].pages.prices.metaTags.title;

    // window.addEventListener("blur", function () {
    //   document.title = "bizi unutmayın"
    // });

    // window.addEventListener("focus", function () {
    //   document.title = a
    // });


  },
  destroyed() {
    document.body.classList.remove("prices", "active-page");
  },
};
</script>
<style>

</style>