<template>
  <main>
    <pageInnerHeaderComponent :lang="lang" :title="'pages.integrations'"></pageInnerHeaderComponent>
    <integrationListCompoenent :lang="lang" :title="'pages.integrations'" />
  </main>
</template>
<script>
import integrationListCompoenent from "@/components/integrationList/integrationList.vue";
import pageInnerHeaderComponent from "@/components/pageInnerHeader/pageInnerHeader.vue";
import tr from "@/lang/tr.js";
import en from "@/lang/en.js";
export default {
  mixins: [tr, en],
  metaInfo() {
    return {
      title: this.metaTitle
        ? this.metaTitle
        : "KOBİSİ HIZLI E-TİCARET PLATFORMU",
      meta: [
        {
          name: "description",
          content: this.metaDescription ? this.metaDescription : "",
        },
        {
          property: "og:title",
          content: this.metaTitle ? this.metaTitle : "KOBİSİ HIZLI E-TİCARET PLATFORMU",
        },
        { name: "robots", content: "index,follow" },
        { property: "og:type", content: "" },
        { property: "og:title", content: " İşinizi kolaylaştıracak tüm entegrasyonlar!" },
        { property: "og:description", content: "Kobisi mağazanızın yanında ürünlerinizi pazaryerlerine entegre ederek pazardaki payınızı büyütün." },
        { property: "og:image", content: "" },
        { property: "og:url", content: "kobisi.com" },
        { property: "og:site_name", content: "Kobisi" },
      ],
    };
  },
  name: "PreferencesView",
  components: {
    integrationListCompoenent,
    pageInnerHeaderComponent,
  },
  data() {
    return {
      metaDescription: null,
      metaTitle: null,
    };
  },
  props: {
    lang: String,
    title: String,
  },
  mounted() {
    document.body.classList.add("integrations", "active-page");
    this.metaTitle = this[this.lang].pages.integrations.metaTags.title;
    this.metaDescription = this[this.lang].pages.integrations.metaTags.description;
  },
  destroyed() {
    document.body.classList.remove("integrations", "active-page");
  },
};
</script>
<style>

</style>