import { render, staticRenderFns } from "./pageInnerHeader.vue?vue&type=template&id=2ddbca5d&scoped=true&v-if=lang%20%26%26%20__(title)&"
import script from "./pageInnerHeader.vue?vue&type=script&lang=js&"
export * from "./pageInnerHeader.vue?vue&type=script&lang=js&"
import style0 from "./pageInnerHeader.scss?vue&type=style&index=0&id=2ddbca5d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ddbca5d",
  null
  
)

export default component.exports