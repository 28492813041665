<template>
  <div>
    <section class="page-inner-header" v-if="PageData">
      <div class="container">
        <div class="paralax-item shape-dotes">
          <div class="paralax-item-inner bottom speed-25">
            <svg class="shape">
              <use
                xlink:href="../../assets/images/icon/shape-sprite.svg#dotes"
              />
            </svg>
          </div>
        </div>
        <div class="paralax-item shape-triangle">
          <div class="paralax-item-inner spin bottom speed-20">
            <svg class="shape">
              <use
                xlink:href="../../assets/images/icon/shape-sprite.svg#triangle"
              />
            </svg>
          </div>
        </div>
        <div class="paralax-item shape-plus">
          <div class="paralax-item-inner bottom speed-3">
            <svg class="shape">
              <use
                xlink:href="../../assets/images/icon/shape-sprite.svg#plus"
              />
            </svg>
          </div>
        </div>
        <div class="row align-items-center text-center">
          <div class="page-title paralax-item-inner bottom speed-15">
            <h1 v-if="PageData">
              {{ PageData.title }}
            </h1>
            <h2 v-if="PageData">{{ PageData.description }}</h2>
          </div>
        </div>
      </div>
    </section>
    <section
      class="cms-section"
      :class="PageData.sideMenu ? 'with-side-bar' : ''"
      role="main"
      v-if="PageData"
    >
      <div class="container" :style="!PageData.sideMenu?'max-width:100%':''">
        <div class="row align-items-start">
        <template v-if="PageData.sideMenu">
          <aside class="col-12 col-md-3">
            <nav>
              <ul>
                <li
                  v-for="(sideMenuItem, index) in PageData.sideMenu"
                  :key="index"
                >
                  <router-link :to="sideMenuItem.slug">{{
                    sideMenuItem.name
                  }}</router-link>
                </li>
              </ul>
            </nav>
          </aside>
        </template>
        <div class="cms-text"
        :class="PageData.sideMenu ? 'col-12 col-md-9' : ''"
         v-html="PageData.content"></div>
      </div>
      </div>
    </section>

    <template v-if="PageData && PageData.faq">
      <section class="faq-section">
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-4">
              <div class="section-title">
                <h3>
                  {{ PageData.faq.title }}
                </h3>
                <p>{{ PageData.faq.text }}</p>
                <a class="bttn radiused primary" href="tel:+908508850615">{{
                  __(general).pricesPage.callUs
                }}</a>
              </div>
            </div>
            <div class="col-12 col-md-7 offset-md-1">
              <div class="faq-wrapper">
                <div
                  class="faq-item"
                  v-for="(faqItem, index) in PageData.faq.list"
                  :key="index"
                  v-on:click="faqOpener(index)"
                  v-bind:class="{ 'faq-acive': currentFaq === index }"
                >
                  <div class="faq-title">
                    <h4>{{ faqItem.title }}</h4>
                    <svg class="shape">
                      <use
                        xlink:href="../../assets/images/icon/icon-sprite.svg#down-arrow"
                      />
                    </svg>
                  </div>
                  <transition
                    mode="out-in"
                    name="faq-active"
                    v-on:before-enter="beforeEnterFaq"
                    v-on:enter="enterFaq"
                  >
                    <div class="faq-description" v-show="currentFaq === index">
                      <div
                        class="inner-wrapper"
                        v-html="faqItem.description"
                      ></div>
                    </div>
                  </transition>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>
  </div>
</template>

  <script>
// Import Swiper Vue.js components
import tr from "@/lang/tr.js";
import en from "@/lang/en.js";

export default {
  mixins: [tr, en],
  name: "cmsDetailContentCompoenent",
  data() {
    return {
      currentFaq: -10,
    };
  },
  props: {
    lang: String,
    PageData: Object,
    general: String,
  },
  methods: {
    faqOpener: function (newFaq) {
      if (newFaq === this.currentFaq) {
        this.currentFaq = -10;
      } else {
        this.currentFaq = newFaq;
      }
    },
    beforeEnterFaq: function (_t) {
      _t.style.display = "block";
      _t.style.maxHeight = null;
      _t.myHeight = _t.offsetHeight;
      _t.style.maxHeight = 0;
      _t.style.display = null;
    },
    enterFaq: function (_t) {
      _t.style.maxHeight = _t.myHeight + "px";
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="./cmsDetailContent.scss" lang="scss" scoped>
</style>
