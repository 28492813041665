<template  v-if="lang && __(title)">
  <section
    :id="__(title).id ? __(title).id : ''"
    class="page-inner-header"
    :class="[
      __(title).dark === true ? 'dark' : 'light',
      __(title).alignCenter === true ? 'align-center' : ''
    ]"
  >
    <div class="container">
      <div class="paralax-item shape-dotes">
        <div class="paralax-item-inner top speed-20">
          <svg class="shape">
            <use xlink:href="../../assets/images/icon/shape-sprite.svg#dotes" />
          </svg>
        </div>
      </div>
      <div class="paralax-item shape-triangle">
        <div class="paralax-item-inner top spin speed-5">
          <svg class="shape">
            <use
              xlink:href="../../assets/images/icon/shape-sprite.svg#triangle"
            />
          </svg>
        </div>
      </div>
      <div class="paralax-item shape-plus">
        <div class="paralax-item-inner bottom speed-10">
          <svg class="shape">
            <use xlink:href="../../assets/images/icon/shape-sprite.svg#plus" />
          </svg>
        </div>
      </div>
      <div class="row row-cols-1 row-cols-sm-2" :class="__(title).alignCenter === true && !__(title).banner ? 'justify-content-center text-center' : ''">
        <div class="page-title paralax-item-inner bottom speed-10">
          <h1>{{ __(title).title }}</h1>
          <h2>{{ __(title).description }}</h2>
          <a
            v-if="__(title).href"
            :href="__(title).href"
            class="bttn primary radiused"
            >{{ __(title).button }}</a
          >
        </div>
        <div
          v-if="__(title).banner"
          class="page-banner paralax-item-inner bottom speed-10"
        >
          <img :src="__(title).banner" alt="" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// Import Swiper Vue.js components
import tr from "@/lang/tr.js";
import en from "@/lang/en.js";
export default {
  mixins: [tr, en],
  name: "pageInnerHeaderComponent",
  props: {
    lang: String,
    title: String,
    id: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="./pageInnerHeader.scss" lang="scss" scoped>
</style>
