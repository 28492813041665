import Vue from 'vue';
import VueRouter from 'vue-router';
import Meta from 'vue-meta';
import IndexView from '../views/index/IndexView.vue';
import ThemesView from '../views/themes/ThemesView.vue';
import PreferencesView from '../views/preferences/PreferencesView.vue';
import IntegrationsView from '../views/integrations/IntegrationsView.vue';
import PricesView from '../views/prices/PricesView.vue';
import IntegrationsDetailView from '../views/integrationsDetail/integrationsDetailView.vue';
import cmsPagesView from '../views/cmsPages/cmsPagesView.vue';
import ReferencesView from '../views/references/ReferencesView.vue';
import LandingPageView from '../views/landingPage/LandingPageView.vue';
import PageNotFoundView from '../views/pageNotFound/PageNotFoundView.vue';

Vue.use(VueRouter); 
Vue.use(Meta, {
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid',
  refreshOnceOnNavigation: true,
});

const routes = [
  {
    path: '/',
    name: 'home',
    alias: ['/home'],
    component: IndexView,
    meta: {
      title: 'Kobisi Gelişmiş E-Ticaret Platformu | Kobisi.com',
      metaTags: [
        {
          name: 'description',
          content:
            'Kendi e-ticaret sitenizde online satış yapmak hiç bu kadar kolay, hızlı ve ulaşılabilir olmamıştı.',
        },
        {
          property: 'og:description',
          content:
            'Kendi e-ticaret sitenizde online satış yapmak hiç bu kadar kolay, hızlı ve ulaşılabilir olmamıştı.',
        },
      ],
    },
  },

  {
    path: '/ozellikler',
    alias: ['/features'],
    name: 'features',
    component: PreferencesView,
  },
  {
    path: '/tasarimlar',
    alias: ['/themes'],
    name: 'themes',
    component: ThemesView,
  },
  {
    path: '/entegrasyonlar',
    alias: ['/integrations'],
    name: 'integrations',
    component: IntegrationsView,
  },
  {
    path: '/fiyatlar',
    alias: ['/pricing'],
    name: 'prices',
    component: PricesView,
  },
  {
    path: '/referanslar',
    alias: ['/references'],
    name: 'references',
    component: ReferencesView,
  },
  {
    path: '/cms/:pageSlug',
    name: 'cmsPages',
    component: cmsPagesView,
  },
  {
    path: '/:category/:platform',
    name: 'integrationsDetail',
    component: IntegrationsDetailView,
  },
  {
    path: '/lp-kobisi-eticaret-paketleri',
    alias: ['/lp-kobisi-ecommerce-packages'],
    name: 'lp-kobisi-ecommerce-packages',
    component: LandingPageView,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'pageNotFound',
    component: PageNotFoundView,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
