import { render, staticRenderFns } from "./pricesTable.vue?vue&type=template&id=d68f236c&scoped=true&"
import script from "./pricesTable.vue?vue&type=script&lang=js&"
export * from "./pricesTable.vue?vue&type=script&lang=js&"
import style0 from "./pricesTable.scss?vue&type=style&index=0&id=d68f236c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d68f236c",
  null
  
)

export default component.exports