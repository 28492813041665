<template  v-if="lang && __(title)">
  <section class="themes" :id="__(title).id" :class="[__(title).reverse == true ? 'reverse' : '']">
    <div class="paralax-item shape-dotes">
      <div class="paralax-item-inner top speed-20">
        <svg class="shape">
          <use xlink:href="../../assets/images/icon/shape-sprite.svg#dotes" />
        </svg>
      </div>
    </div>
    <div class="section-wrap">
      <div class="container">
        <div class="section-title hrz">
          <div class="inner-wrapper">
            <h2 v-if="lang">{{ __(title).title }}</h2>
            <h3>{{ __(title).subTitle }}</h3>
          </div>
          <!-- Temalar eklenince aktif edilecek -->
          <!-- <div class="inner-wrapper">
                                <a href="" class="bttn link primary">
                                    Daha fazla tema  <svg class="shape">
                                    <use xlink:href="../../assets/images/icon/icon-sprite.svg#right-arrow" />
                                </svg>
                                </a>
                            </div> -->
        </div>
        <div class="button-container">
          <div class="container">
            <div class="buttons">
              <div class="swiper-button-prev">
                <svg class="shape">
                  <use xlink:href="../../assets/images/icon/icon-sprite.svg#back" />
                </svg>
              </div>
              <div><span id="numberSlides"></span></div>
              <div class="swiper-button-next">
                <svg class="shape">
                  <use xlink:href="../../assets/images/icon/icon-sprite.svg#next" />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div class="themes-list slider-list swiper" ref="swiper">
          <div class="slider-wrap swiper-wrapper">
            <div class="themes-list-item swiper-slide" v-for="listitem in __(title).list" :key="listitem.id">
              <div class="inner-wrapper" :class="[__(title).animated == true ? 'animated' : '']">
                <div class="theme-preview">
                  <router-link :to="listitem.href">
                    <img :src="listitem.thumb" alt="" />
                  </router-link>
                </div>
                <div class="theme-info">
                  <router-link :to="listitem.href">
                    <h4>{{ listitem.name }}</h4>
                    <span>{{ listitem.shortDescription }}</span>
                  </router-link>
                </div>
              </div>
            </div>
            <div class="themes-list-item swiper-slide the-more">
              <div class="inner-wrapper animated">
                <router-link to="tasarimlar">
                  {{ __(title).theMore }}
                  <svg class="shape">
                    <use xlink:href="../../assets/images/icon/icon-sprite.svg#right-arrow" />
                  </svg>
                </router-link>
              </div>
            </div>
          </div>
          <!-- <div class="swiper-button-next">
            <svg class="shape">
              <use xlink:href="../../assets/images/icon/icon-sprite.svg#next" />
            </svg>
          </div>
          <div class="swiper-button-prev">
            <svg class="shape">
              <use xlink:href="../../assets/images/icon/icon-sprite.svg#back" />
            </svg>
          </div> -->
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Swiper, { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import tr from "@/lang/tr.js";
import en from "@/lang/en.js";
export default {
  mixins: [tr, en],
  name: "showcaseComponent",
  props: {
    lang: String,
    title: String,
  },
  mounted() {
    new Swiper(this.$refs.swiper, {
      // configure Swiper to use modules
      modules: [Navigation, Pagination],

      // Optional parameters
      slidesPerView: 2,
      spaceBetween: 40,
      autoHeight: true,
      loopFillGroupWithBlank: false,
      loop: false,

      centeredSlides: true,
      centeredSlidesBounds: false,
      // If we need pagination
      pagination: {
        el: ".swiper-pagination",
      },
      // Navigation arrows
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      on: {
        init: function (sw) {
          var offer = document.querySelector("#numberSlides");
          offer.innerHTML = sw.activeIndex + 1 + " / " + (sw.slides.length - 1)
        },
        slideChange: function (sw) {
          var offer = document.querySelector("#numberSlides");
          offer.innerHTML = sw.activeIndex + 1 + " / " + (sw.slides.length - 1)
        },
      },
      breakpoints: {
        768: {
          slidesPerView: 3,
          slidesPerGroup: 3,
          spaceBetween: 40,
          centeredSlides: false,
        },
        1280: {
          slidesPerView: 4,
          slidesPerGroup: 1,
          spaceBetween: 40,
          centeredSlides: false,
        },
      },

      // And if we need scrollbar
      scrollbar: {
        el: ".swiper-scrollbar",
      },
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="./showcase.scss" lang="scss" scoped>

</style>
