<template  v-if="lang && __(title)">
  <section
    class="references-logo-list"
    :id="__(title).id"
    :class="[
      __(title).reverse == true ? 'reverse' : '',
      __(title).dark == true ? 'dark' : '',
    ]"
  >
    <div class="paralax-item shape-circle">
      <div class="paralax-item-inner top speed-5">
        <svg class="shape">
          <use xlink:href="../../assets/images/icon/shape-sprite.svg#circle" />
        </svg>
      </div>
    </div>
    <div class="paralax-item shape-triangle">
      <div class="paralax-item-inner spin speed-6">
        <svg class="shape">
          <use
            xlink:href="../../assets/images/icon/shape-sprite.svg#triangle"
          />
        </svg>
      </div>
    </div>
    <div class="section-wrap">
      <div class="container">
        <div
          class="
            row row-cols-2 row-cols-md-6
            align-items-center
            justify-content-center
          "
        >
          <div
            class="references-logo-item"
            v-for="(listitem,index) in __(title).list"
            :key="index"
          >
            <a target="blank">
              <img :src="listitem.image" alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

    <script>
// Import Swiper Vue.js components
import tr from "@/lang/tr.js";
import en from "@/lang/en.js";
export default {
  mixins: [tr, en],
  name: "referencesComponent",
  props: {
    lang: String,
    title: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="./references.scss" lang="scss" scoped>
</style>
