<template  v-if="lang && __(title)">
  <section class="side-menu-content" :id="__(title).id">
    <div class="container">
      <div class="row">
        <!-- <aside class="col-12 col-md-3">
          <div class="side-menu">
            <ul>
              <li
                @click="goto(platform.slug)"
                v-for="platform in __(title).menu"
                :key="platform.category"
              >
                {{ platform.category }}
              </li>
            </ul>
          </div>
        </aside> -->

        <div class="related-contents">
          <div
            class="related-contents-groups"
            :id="cattegoriesItem.slug"
            v-for="cattegoriesItem in __(title).categories"
            :key="cattegoriesItem.categoryName"
          >
            <h2>{{ cattegoriesItem.categoryName }}</h2>
            <div class="row row-cols-1 row-cols-sm-3 row-cols-lg-4">
              <div
                class="related-contents-groups-item"
                v-for="categoryItem in cattegoriesItem.platforms"
                :key="categoryItem.name"
              >
                <router-link
                  :to="{
                    name: 'integrationsDetail',
                    params: {
                      category: cattegoriesItem.slug,
                      platform: categoryItem.slug,
                    },
                  }"
                >
                  <!-- <router-link :to="categoryItem.slug"> -->
                  <div class="integration-logo">
                    <img :src="categoryItem.logo" :alt="categoryItem.name" />
                  </div>
                  <div class="integration-name">
                    {{ categoryItem.name }}
                    <span>{{ cattegoriesItem.categoryName }}</span>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

  <script>
// Import Swiper Vue.js components
import tr from "@/lang/tr.js";
import en from "@/lang/en.js";
export default {
  mixins: [tr, en],
  name: "integrationListComponent",
  props: {
    lang: String,
    title: String,
  },
  methods: {
    goto(targetRef) {
      document.getElementById(targetRef).scrollIntoView({
        behavior: "smooth",
        block: "center",
      });

      // var element = this.$refs[targetRef];
      // var top = element.offsetTop;

      // window.scrollTo(0, top);
      // console.log(this.$refs)
    },
  },
  mounted() {
    document.body.classList.add("integrations", "active-page");
  },
  destroyed() {
    document.body.classList.remove("integrations", "active-page");
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="./integrationList.scss" lang="scss" scoped>
</style>
