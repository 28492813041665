<template>
  <section id="main-slider" class="masthead">
    <div class="masthead-wrap">
      <div class="paralax-item shape-dotes">
        <div class="paralax-item-inner top speed-20">
          <svg class="shape">
            <use xlink:href="../../assets/images/icon/shape-sprite.svg#dotes" />
          </svg>
        </div>
      </div>
      <div class="paralax-item shape-zigzag">
        <div class="paralax-item-inner spin speed-1">
          <svg class="shape">
            <use
              xlink:href="../../assets/images/icon/shape-sprite.svg#zigzag"
            />
          </svg>
        </div>
      </div>
      <div class="paralax-item shape-triangle">
        <div class="paralax-item-inner top spin speed-2">
          <svg class="shape">
            <use
              xlink:href="../../assets/images/icon/shape-sprite.svg#triangle"
            />
          </svg>
        </div>
      </div>
      <div class="paralax-item shape-plus">
        <div class="paralax-item-inner bottom speed-10">
          <svg class="shape">
            <use xlink:href="../../assets/images/icon/shape-sprite.svg#plus" />
          </svg>
        </div>
      </div>
      <div class="paralax-item shape-circle">
        <div class="paralax-item-inner top left spin speed-5">
          <svg class="shape">
            <use
              xlink:href="../../assets/images/icon/shape-sprite.svg#circle"
            />
          </svg>
        </div>
      </div>
      <div class="masthead-item">
        <template v-if="__(title).image">
          <picture>
            <source media="(max-width:991px)" :srcset="__(title).image" />
            <img :src="__(title).image" alt="" />
          </picture>
        </template>
      </div>
      <div class="masthead-content">
        <div class="container">
          <h2>{{ __(title).title }}</h2>
          <h3>{{ __(title).text }}</h3>
          <trialFormComponent :lang="lang" general="general"/>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import trialFormComponent from "@/components/trialForm/trialForm.vue";
import tr from "@/lang/tr.js";
import en from "@/lang/en.js";
export default {
  mixins: [tr, en],
  name: "mastheadComponent",
  props: {
    lang: String,
    title: String,
    general: String,
  },
  components:{
   trialFormComponent
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="./masthead.scss" lang="scss" scoped>
</style>
