import { render, staticRenderFns } from "./verticalInfographic.vue?vue&type=template&id=47b73875&scoped=true&"
import script from "./verticalInfographic.vue?vue&type=script&lang=js&"
export * from "./verticalInfographic.vue?vue&type=script&lang=js&"
import style0 from "./verticalInfographic.scss?vue&type=style&index=0&id=47b73875&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47b73875",
  null
  
)

export default component.exports