import { render, staticRenderFns } from "./verticalLogo.vue?vue&type=template&id=7e8ebf69&scoped=true&v-if=lang%20%26%26%20__(title)&"
import script from "./verticalLogo.vue?vue&type=script&lang=js&"
export * from "./verticalLogo.vue?vue&type=script&lang=js&"
import style0 from "./verticalLogo.scss?vue&type=style&index=0&id=7e8ebf69&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e8ebf69",
  null
  
)

export default component.exports