<template>
  <main>
    <integrationListItemComponent
      :lang="lang"
      :IntegrationItem="integrationItem"
      general="general"
    ></integrationListItemComponent>
  </main>
</template>
<script>
import integrationListItemComponent from "@/components/integrationListItem/integrationListItem.vue";

import tr from "@/lang/tr.js";
import en from "@/lang/en.js";

export default {
  mixins: [tr, en],
  name: "IntegrationsDetailView",
  metaInfo() {
    return {
      title: this.metaTitle
        ? this.metaTitle
        : "Kobisi Gelişmiş E-ticaret Platformu",
      meta: [
        {
          name: "description",
          content: this.metaDescription
            ? this.metaDescription
            : "Kobisi mağazanızın yanında ürünlerinizi pazaryerlerine entegre ederek pazardaki payınızı büyütün.",
        },
        {
          property: "og:title",
          content: this.metaTitle
            ? this.metaTitle
            : "KOBİSİ HIZLI E-TİCARET PLATFORMU",
        },
        { name: "robots", content: "index,follow" },
        { property: "og:type", content: "" },
        {
          property: "og:title",
          content: " İşinizi kolaylaştıracak tüm entegrasyonlar!",
        },
        {
          property: "og:description",
          content:
            "Kobisi mağazanızın yanında ürünlerinizi pazaryerlerine entegre ederek pazardaki payınızı büyütün.",
        },
        { property: "og:image", content: "" },
        { property: "og:url", content: "kobisi.com" },
        { property: "og:site_name", content: "Kobisi" },
      ],
    };
  },
  components: {
    integrationListItemComponent,
  },
  props: {
    lang: String,
    IntegrationItem: Object,
    general: String,
  },
  data() {
    return {
      integrationItem: null,
      metaTitle: null,
      metaDescription: null,
    };
  },
  beforeUpdate() {
    const langData = this[this.lang];
    let getCategory = langData.pages.integrations.categories.find((category) =>
      category.platforms.some(
        (platform) => platform.slug === this.$route.params.platform
      )
    );
    this.integrationItem = getCategory.platforms.find(
      (iii) => iii.slug == this.$route.params.platform
    );
    this.metaTitle = this.integrationItem.title;
    this.metaDescription = this.integrationItem.description;
  },
  mounted() {
    const langData = this[this.lang];
    let getCategory = langData.pages.integrations.categories.find((category) =>
      category.platforms.some(
        (platform) => platform.slug === this.$route.params.platform
      )
    );
    this.integrationItem = getCategory.platforms.find(
      (iii) => iii.slug == this.$route.params.platform
    );
    this.metaTitle = this.integrationItem.title;
    this.metaDescription = this.integrationItem.description;

    // let category=langData.pages.integrations.categories.find( x=>x.slug===this.$route.params.category)
    // this.integrationItem=category.platforms.find(iii=>iii.slug== this.$route.params.platform);

    document.body.classList.add("integrationDetail", "active-page");
  },
  destroyed() {
    document.body.classList.remove("integrationDetail", "active-page");
  },
};
</script>
<style>
</style>

  <!-- console.log(langData.pages.integrations.categories.pazaryerleri.hepsiburada); -->