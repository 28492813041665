<template  v-if="lang">
  <footer id="footer">
    <div class="container">
      <div class="footer-wrapper">
        <div class="f-area first-side">
          <div class="logo">
            <img :src="__(general).logoLight" :alt="__(general).siteTitle" />
          </div>
          <div class="contact-legal">
            <div class="address">
              <div class="address-row">
                <a
                  target="_blank"
                  href="https://www.google.com/maps/place/%C4%B0T%C3%9C+MAGNET/@41.1075604,29.0270585,18.46z/data=!4m5!3m4!1s0x14cab5eb9ae0b3d3:0x180728e8ec4fc28c!8m2!3d41.107559!4d29.027797"
                  >İstanbul Teknik Üniversitesi ARI 4</a
                >
              </div>
              <div class="address-row">
                <a href="tel:+908508850615">0 850 885 06 15</a>
              </div>
              <div class="address-row">
                <a href="mailto:sorular@kobisi.com">sorular@kobisi.com</a>
              </div>
            </div>
          </div>
        </div>
        <div class="f-area second-side">
          <div class="f-area footer-links">
            <div class="footer-links-area">
              <div
                class="footer-links-area-item"
                v-for="(linksGroupItem, index) in __(title).linksGroup"
                :key="index"
              >
                <h2
                  v-on:click="faqOpener(index)"
                  v-bind:class="{ 'active-menu': currentItem === index }"
                >
                  {{ linksGroupItem.title }}
                  <svg class="shape">
                    <use
                      xlink:href="../../assets/images/icon/icon-sprite.svg#down-arrow"
                    />
                  </svg>
                </h2>
                <template v-if="linksGroupItem.id != 'socialMedia'">
                  <transition
                    mode="out-in"
                    name="active-menu"
                    v-on:before-enter="beforeEnter"
                    v-on:enter="enter"
                  >
                    <ul
                      v-show="currentItem === index"
                      :class="{
                        'social-menu': linksGroupItem.id == 'socialMedia',
                        'active-menu': currentItem === index,
                      }"
                    >
                      <li
                        v-for="linksItem in linksGroupItem.routeLinks"
                        :key="linksItem.title"
                      >
                        <!-- <a href="">{{ linksItem.pagename }}</a> -->
                        <template v-if="!linksItem.target">
                          <router-link
                            :to="
                              linksItem.slug && linksItem.slug != null
                                ? {
                                    name: 'cmsPages',
                                    params: { pageSlug: linksItem.slug },
                                  }
                                : linksItem.href
                            "
                            >{{ linksItem.pagename }}</router-link
                          >
                        </template>
                        <template v-if="linksItem.target">
                          <a :href="linksItem.href" target="blank">{{
                            linksItem.pagename
                          }}</a>
                        </template>
                      </li>
                    </ul>
                  </transition>
                </template>
                <template v-else>
                  <transition
                    mode="out-in"
                    name="active-menu"
                    v-on:before-enter="beforeEnter"
                    v-on:enter="enter"
                  >
                    <ul
                      v-show="currentItem === index"
                      :class="{
                        'social-menu': linksGroupItem.id == 'socialMedia',
                        'active-menu': currentItem === index,
                      }"
                    >
                      <li
                        v-for="socialItem in __(general).socialMediaLinks.links"
                        :key="socialItem.name"
                      >
                        <a :href="'//' + socialItem.route" target="_blank">
                          <svg class="shape">
                            <use
                              :xlink:href="`${spriteUrl}#` + socialItem.id"
                            />
                          </svg>
                          {{ socialItem.pagename }}</a
                        >
                      </li>
                    </ul>
                  </transition>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-bottom">
          <div class="legal-text">Copyright © 2017 - 2022. Kobisi</div>
          <div class="lang-select">
            <div class="selected-lang" tabindex="1">
              <svg class="shape">
                <use :xlink:href="`${spriteUrl}#language`" />
              </svg>
              <span :v-model="'lang'">{{ __("lang") }}</span>
            </div>
            <ul>
              <li
                @click="changeLang('tr'), addClass(t)"
                :class="{ active: selectedItem == t }"
              >
                <span>Türkçe</span>
              </li>
              <li
                @click="changeLang('en'), addClass(t)"
                :class="{ active: selectedItem == t }"
              >
                <span>English</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import tr from "@/lang/tr.js";
import en from "@/lang/en.js";
const iconPath = require("@/assets/images/icon/icon-sprite.svg");
export default {
  mixins: [tr, en],
  name: "footerComponent",
  data() {
    return {
      spriteUrl: iconPath,
      currentItem: -10,
      selectedItem: 0,
      t: null,
    };
  },
  props: {
    lang: String,
    title: String,
    general: String,
  },
  methods: {
    changeLang(lang) {
      this.$emit("changelang", lang);

      // document.location.reload();
      // window.location.reload();

    localStorage.setItem("lang",this.lang=='tr'?'en':'tr')
    },
    addClass(t) {
      this.selectedItem = t;
    },
    faqOpener: function (newItem) {
      if (newItem === this.currentItem) {
        this.currentItem = -10;
      } else {
        this.currentItem = newItem;
      }
    },
    beforeEnter: function (_t) {
      _t.style.display = "block";
      _t.style.maxHeight = null;
      _t.myHeight = _t.offsetHeight;
      _t.style.maxHeight = 0;
      _t.style.display = null;
    },
    enter: function (_t) {
      _t.style.maxHeight = _t.myHeight + "px";
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="./footer.scss" lang="scss" scoped>
</style>
