<template  v-if="lang && __(title)">
  <section
    :id="__(title).id"
    :class="[
      __(title).reverse == true ? 'reverse' : '',
      __(title).dark == true ? 'dark' : '',
    ]"
    class="recommendation"
  >
    <div class="paralax-item shape-dotes">
      <div class="paralax-item-inner top speed-10">
        <svg class="shape">
          <use xlink:href="../../assets/images/icon/shape-sprite.svg#dotes" />
        </svg>
      </div>
    </div>
    <div class="paralax-item shape-circle">
      <div class="paralax-item-inner right top speed-3">
        <svg class="shape">
          <use xlink:href="../../assets/images/icon/shape-sprite.svg#circle" />
        </svg>
      </div>
    </div>
    <div class="paralax-item shape-triangle">
      <div class="paralax-item-inner spin speed-3">
        <svg class="shape">
          <use
            xlink:href="../../assets/images/icon/shape-sprite.svg#triangle"
          />
        </svg>
      </div>
    </div>
    <div class="section-wrap">
      <div class="container">
        <div class="row">
          <div class="section-title cntr">
            <h2>{{ __(title).title }}</h2>
            <h3>{{ __(title).subTitle }}</h3>
          </div>
        </div>
        <div
          class="recommendation-list slider-list swiper"
          ref="recommendation"
        >
          <div class="slider-wrap swiper-wrapper">
            <div
              class="recommendation-list-item text-center swiper-slide"
              :class="[__(title).animated == true ? 'animated' : '']"
              v-for="listItem in __(title).list"
              :key="listItem.id"
            >
              <div class="customer-logo">
                <img :src="listItem.image" alt="" />
              </div>
              <div class="customer-text">{{ listItem.text }}</div>
              <div class="customer-name">
                {{ listItem.owner }} <span>{{ listItem.jobTitle }}</span>
              </div>
            </div>
          </div>
          <div class="swiper-button-next">
            <svg class="shape">
              <use xlink:href="../../assets/images/icon/icon-sprite.svg#next" />
            </svg>
          </div>
          <div class="swiper-button-prev">
            <svg class="shape">
              <use xlink:href="../../assets/images/icon/icon-sprite.svg#back" />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

    <script>
// Import Swiper Vue.js components
import Swiper, { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import tr from "@/lang/tr.js";
import en from "@/lang/en.js";
export default {
  mixins: [tr, en],
  name: "recommendationComponent",
  props: {
    lang: String,
    title: String,
  },
  mounted() {
    new Swiper(this.$refs.recommendation, {
      // configure Swiper to use modules
      modules: [Navigation, Pagination],

      // Optional parameters
      slidesPerView: 1,
      spaceBetween: 40,
      autoHeight: true,
      loopFillGroupWithBlank: false,
      loop: false,

      centeredSlides: true,
      centeredSlidesBounds: false,
      // If we need pagination
      pagination: {
        el: ".swiper-pagination",
      },
      // Navigation arrows
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },

      // And if we need scrollbar
      scrollbar: {
        el: ".swiper-scrollbar",
      },
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="./recommendation.scss" lang="scss" scoped>
</style>
