<template >
  <section
    class="vertical-infographic"
    :id="__(title).id"
    :class="[
      __(title).reverse == true ? 'reverse' : '',
      __(title).dark == true ? 'dark' : '',
    ]"
  >
    <div class="paralax-item shape-circle">
      <div class="paralax-item-inner right top speed-6">
        <svg class="shape">
          <use xlink:href="../../assets/images/icon/shape-sprite.svg#circle" />
        </svg>
      </div>
    </div>
    <div class="paralax-item shape-triangle">
      <div class="paralax-item-inner spin speed-6">
        <svg class="shape">
          <use
            xlink:href="../../assets/images/icon/shape-sprite.svg#zigzag"
          />
        </svg>
      </div>
    </div>
    <div class="paralax-item shape-dotes">
      <div class="paralax-item-inner bottom speed-6">
        <svg class="shape">
          <use xlink:href="../../assets/images/icon/shape-sprite.svg#dotes" />
        </svg>
      </div>
    </div>
    <div class="section-wrap">
      <div class="container">
        <div class="row">
          <div class="section-title cntr">
            <h2 v-if="lang && __(title)">{{ __(title).title }}</h2>
            <h3 v-if="lang && __(title)">{{ __(title).subTitle }}</h3>
          </div>
        </div>
        <div class="row row-cols-1 row-cols-sm-2">
          <div
            class="vertical-infographic-item"
            :class="[__(title).animated == true ? 'animated' : '']"
            v-for="listitem in __(title).list"
            :key="listitem.id"
          >
            <div class="shape-icon">
              <svg class="shape">
                <use
                  :xlink:href="
                    require('../../assets/images/icon/icon-sprite.svg') +
                    '#' +
                    listitem.icon
                  "
                />
              </svg>
            </div>
            <div class="info">
              <h3  v-if="lang && __(title)">{{ listitem.title }}</h3>
              <p  v-if="lang && __(title)">
                {{ listitem.shortDescription }}
              </p>
            </div>
          </div>
        </div>
        <div class="section-bottom d-flex justify-content-center">
          <a :href="__(title).href" :class="'bttn link primary'"  v-if="lang && __(title)"
            >{{ __(title).button }}
            <svg class="shape">
              <use
                xlink:href="../../assets/images/icon/icon-sprite.svg#right-arrow"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// Import Swiper Vue.js components
import tr from "@/lang/tr.js";
import en from "@/lang/en.js";
export default {
  mixins: [tr, en],
  name: "verticalInfographicComponent",
  props: {
    lang: String,
    title: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="./verticalInfographic.scss" lang="scss" scoped>
</style>
