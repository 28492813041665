<template>
  <div id="app">


    <!-- <LetItSnow v-bind="snowConf" :show="show" /> -->

    <headerComponent :lang="lang" :title="'header'" general="general" :IsDark="isDark" @changelang="lang = $event" />

    <vue-page-transition name="fade">
      <router-view :lang="lang" :title="'pages'" />
    </vue-page-transition>

    <footerComponent :lang="lang" :title="'footer'" general="general" @changelang="lang = $event" />
  </div>
</template>

<script>
// @ is an alias to /src
import headerComponent from "@/components/header/header.vue";
import footerComponent from "@/components/footer/footer.vue";
import Vue from "vue";
import VuePageTransition from "vue-page-transition";
import "@/assets/scss/style.scss";
import tr from "@/lang/tr.js";
import en from "@/lang/en.js";

// import Snowf from 'vue-snowf';
import LetItSnow from 'vue-let-it-snow';
Vue.use(LetItSnow);
export default {


  mixins: [tr, en],
  name: "IndexView",
  data() {
    return {
      lang: "tr",
      page: null,
      isDark: "",
      // snowConf: {
      //   windPower: 2,
      //   speed: 0.1,
      //   count: 10,
      //   size: 3,
      //   opacity: 0.1,
      //   images: ['https://raw.githubusercontent.com/bob-chen/let_it_snow/master/demo/snow.png']
      // },
      // show: false
    };
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Kobisi Gelişmiş E-Tİcaret Platformu",
    // all titles will be injected into this template
    titleTemplate: "%s | Kobisi.com",
  },
  components: {
    headerComponent,
    footerComponent,
    LetItSnow

  },
  beforeUpdate() {
    let currentLang = localStorage.getItem("lang");
    this.lang = currentLang ? currentLang : "tr";
    document.getElementsByTagName('html')[0].setAttribute("lang", currentLang ? currentLang : "tr")
    this.page = this.$route.name;
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

    //header dark or light function
    let darkProperty = this[this.lang].pages[this.$route.name];
    if (darkProperty !== undefined) {
      this.isDark = darkProperty.dark;
    } else {
      this.isDark = false;
    }
  },
  mounted() {
    //header dark or light function
    let darkProperty = this[this.lang].pages[this.$route.name];
    // console.log(darkProperty);
    if (darkProperty !== undefined) {
      this.isDark = darkProperty.dark;
    } else {
      this.isDark = false;
    }
    let selectedLang = localStorage.getItem("lang");
    this.lang = selectedLang;
    // this.show = true
    Vue.use(LetItSnow);

  },
  updated() {
    //header dark or light function
    let darkProperty = this[this.lang].pages[this.$route.name];
    // console.log(darkProperty);
    if (darkProperty !== undefined) {
      this.isDark = darkProperty.dark;
    } else {
      this.isDark = false;
    }
  },
};

Vue.use(VuePageTransition);

//Checks which kind of movements buond to an element
const checkMovement = (item) => {
  let classesList = item.className.split(" ");
  let result = {
    speed: 1,
    direction: null,
    type: null,
    item: item,
  };

  for (let className of classesList) {
    // hareket tanmlamaları (sağ sol alt üst vb)
    switch (className) {
      case "spin":
        result.type = className;
        break;
      case "spinBackwards":
        result.type = className;
        break;
      case "zoom":
        result.type = className;
        break;
      case "top":
        result.direction = "bottom";
        break;
      case "bottom":
        result.direction = "top";
        break;
      case "left":
        result.direction = "right";
        break;
      case "right":
        result.direction = "left";
        break;
      case "paralax-item-inner":
        break;
      default:
        result.speed = getSpeed(className);
        break;
    }
  }

  return result;
};

Vue.mixin({
  methods: {
    __(param) {
      // var routeName=this.$route.name;
      // return this[this.lang]
      let value = this[this.lang];
      param.split(".").forEach((item) => {
        value = value[item];
      });
      return value;
      // console.log('this[this.lang].'+ routeName +"."+ param)
      //  return eval('this[this.lang].' + param);
    },
  },
});

//yakınlaştırma
const zoom = (item, speed, scroll) => {
  let transformation = (100 / (scroll / 10)) * (1 / speed);

  if (transformation <= 1) item.style.transform = `scale(${transformation})`;
  else item.style.transform = `scale(1)`;
};

//get location ip lokasyona göre dil çıkacak
// fetch("https://api.ipregistry.co/?key=tryout")
//   .then(function (response) {
//     return response.json();
//   })
//   .then(function (payload) {
//     console.log(payload);
//   });

//döndürme
const spin = (item, speed, scroll) => {
  let rotation = (scroll / 30) * speed;

  item.style.transform = `rotate(${rotation}deg)`;
};

const spinBackwards = (item, speed, scroll) => {
  let rotation = (scroll / 30) * speed;

  item.style.transform = `rotate(-${rotation}deg)`;
};

//sağ sol alt üst hareken - direction parametresi ile
const move = (item, direction, speed, scroll) => {
  let movementSize = (scroll / 50) * speed;
  item.style[direction] = movementSize + "px";
};

//kısıtlamalar
const bindMovementsToElement = (item, speed, direction, type, scroll) => {
  if (type && type === "zoom") zoom(item, speed, scroll);
  if (type && type === "spin") spin(item, speed, scroll);
  if (type && type === "spinBackwards") spinBackwards(item, speed, scroll);
  if (direction) move(item, direction, speed, scroll);
};

const getSpeed = (className) => {
  let speed = 1;
  let name = "" + className;

  let re = /speed/i;
  let myArray = name.match(re);

  if (myArray && myArray.length > 0) {
    let speedNum = name.split("-");
    speed = +speedNum[1];
  }

  return speed;
};

window.addEventListener("scroll", () => {
  let doc = document.getElementsByTagName("body");
  let top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);

  let items = document.querySelectorAll(".paralax-item-inner");

  for (const item of items) {
    let { speed, direction, type } = checkMovement(item);

    bindMovementsToElement(item, speed, direction, type, top);
  }
});

function reveal() {
  var reveals = document.querySelectorAll(".animated");

  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;

    if (elementTop < windowHeight) {
      reveals[i].classList.add("active");
    } else {
      reveals[i].classList.remove("active");
    }
  }
}

window.addEventListener("scroll", function () {
  var header = document.getElementById("header");
  var currentScroll =
    window.pageYOffset ||
    document.documentElement.scrollTop ||
    document.body.scrollTop ||
    0;
  if (currentScroll > 100) {
    header.classList = "scrolled";
  } else {
    header.classList.remove("scrolled");
  }
});

window.addEventListener("scroll", reveal);
window.addEventListener("load", reveal);





</script>

