var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('footer',{attrs:{"id":"footer"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"footer-wrapper"},[_c('div',{staticClass:"f-area first-side"},[_c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":_vm.__(_vm.general).logoLight,"alt":_vm.__(_vm.general).siteTitle}})]),_vm._m(0)]),_c('div',{staticClass:"f-area second-side"},[_c('div',{staticClass:"f-area footer-links"},[_c('div',{staticClass:"footer-links-area"},_vm._l((_vm.__(_vm.title).linksGroup),function(linksGroupItem,index){return _c('div',{key:index,staticClass:"footer-links-area-item"},[_c('h2',{class:{ 'active-menu': _vm.currentItem === index },on:{"click":function($event){return _vm.faqOpener(index)}}},[_vm._v(" "+_vm._s(linksGroupItem.title)+" "),_c('svg',{staticClass:"shape"},[_c('use',{attrs:{"xlink:href":require("../../assets/images/icon/icon-sprite.svg") + "#down-arrow"}})])]),(linksGroupItem.id != 'socialMedia')?[_c('transition',{attrs:{"mode":"out-in","name":"active-menu"},on:{"before-enter":_vm.beforeEnter,"enter":_vm.enter}},[_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentItem === index),expression:"currentItem === index"}],class:{
                      'social-menu': linksGroupItem.id == 'socialMedia',
                      'active-menu': _vm.currentItem === index,
                    }},_vm._l((linksGroupItem.routeLinks),function(linksItem){return _c('li',{key:linksItem.title},[(!linksItem.target)?[_c('router-link',{attrs:{"to":linksItem.slug && linksItem.slug != null
                              ? {
                                  name: 'cmsPages',
                                  params: { pageSlug: linksItem.slug },
                                }
                              : linksItem.href}},[_vm._v(_vm._s(linksItem.pagename))])]:_vm._e(),(linksItem.target)?[_c('a',{attrs:{"href":linksItem.href,"target":"blank"}},[_vm._v(_vm._s(linksItem.pagename))])]:_vm._e()],2)}),0)])]:[_c('transition',{attrs:{"mode":"out-in","name":"active-menu"},on:{"before-enter":_vm.beforeEnter,"enter":_vm.enter}},[_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentItem === index),expression:"currentItem === index"}],class:{
                      'social-menu': linksGroupItem.id == 'socialMedia',
                      'active-menu': _vm.currentItem === index,
                    }},_vm._l((_vm.__(_vm.general).socialMediaLinks.links),function(socialItem){return _c('li',{key:socialItem.name},[_c('a',{attrs:{"href":'//' + socialItem.route,"target":"_blank"}},[_c('svg',{staticClass:"shape"},[_c('use',{attrs:{"xlink:href":_vm.spriteUrl + "#" + socialItem.id}})]),_vm._v(" "+_vm._s(socialItem.pagename))])])}),0)])]],2)}),0)])]),_c('div',{staticClass:"footer-bottom"},[_c('div',{staticClass:"legal-text"},[_vm._v("Copyright © 2017 - 2022. Kobisi")]),_c('div',{staticClass:"lang-select"},[_c('div',{staticClass:"selected-lang",attrs:{"tabindex":"1"}},[_c('svg',{staticClass:"shape"},[_c('use',{attrs:{"xlink:href":(_vm.spriteUrl + "#language")}})]),_c('span',{attrs:{"v-model":'lang'}},[_vm._v(_vm._s(_vm.__("lang")))])]),_c('ul',[_c('li',{class:{ active: _vm.selectedItem == _vm.t },on:{"click":function($event){_vm.changeLang('tr'), _vm.addClass(_vm.t)}}},[_c('span',[_vm._v("Türkçe")])]),_c('li',{class:{ active: _vm.selectedItem == _vm.t },on:{"click":function($event){_vm.changeLang('en'), _vm.addClass(_vm.t)}}},[_c('span',[_vm._v("English")])])])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contact-legal"},[_c('div',{staticClass:"address"},[_c('div',{staticClass:"address-row"},[_c('a',{attrs:{"target":"_blank","href":"https://www.google.com/maps/place/%C4%B0T%C3%9C+MAGNET/@41.1075604,29.0270585,18.46z/data=!4m5!3m4!1s0x14cab5eb9ae0b3d3:0x180728e8ec4fc28c!8m2!3d41.107559!4d29.027797"}},[_vm._v("İstanbul Teknik Üniversitesi ARI 4")])]),_c('div',{staticClass:"address-row"},[_c('a',{attrs:{"href":"tel:+908508850615"}},[_vm._v("0 850 885 06 15")])]),_c('div',{staticClass:"address-row"},[_c('a',{attrs:{"href":"mailto:sorular@kobisi.com"}},[_vm._v("sorular@kobisi.com")])])])])}]

export { render, staticRenderFns }