<template v-if="pageData">
  <main>
    <cmsDetailContentCompoenent
      :lang="lang"
      :PageData="pageData"
      general="general"
    ></cmsDetailContentCompoenent>
  </main>
</template>
<script>
import cmsDetailContentCompoenent from "@/components/cmsDetailContent/cmsDetailContent.vue";
import tr from "@/lang/tr.js";
import en from "@/lang/en.js";

export default {
  mixins: [tr, en],
  name: "cmsPagesView",

  metaInfo() {
    return {
      title: this.metaTitle
        ? this.metaTitle
        : "KOBİSİ HIZLI E-TİCARET PLATFORMU",
      meta: [
        {
          name: "description",
          content: this.metaDescription ? this.metaDescription : "",
        },
        {
          property: "og:title",
          content: this.metaTitle ? this.metaTitle : "",
        },
        { name: "robots", content: "index,follow" },
        { property: "og:type", content: "" },
        { property: "og:title", content: " İşinizi kolaylaştıracak tüm entegrasyonlar!" },
        { property: "og:description", content: "Kobisi mağazanızın yanında ürünlerinizi pazaryerlerine entegre ederek pazardaki payınızı büyütün." },
        { property: "og:image", content: "" },
        { property: "og:url", content: "kobisi.com" },
        { property: "og:site_name", content: "Kobisi" },
      ],
    };
  },
  components: {
    cmsDetailContentCompoenent,
  },
  props: {
    title: String,
    lang: String,
    general: String,
    // PageData: String,
  },
  data() {
    return {
      pageData: null,
      metaTitle: null,
      metaDescription: null,
    };
  },
  beforeUpdate() {
    const langData = this[this.lang];

    let getCategory = langData.pages.cms.find((a) =>
      a.pages.some((b) => b.slug === this.$route.params.pageSlug)
    );
    this.pageData = getCategory.pages.find(
      (c) => c.slug == this.$route.params.pageSlug
    );
    this.metaTitle = this.pageData.title;
    this.metaDescription = this.pageData.description;
  },
  mounted() {
    const langData2 = this[this.lang];

 
    let getCategory2 = langData2.pages.cms.find((x) =>
      x.pages.some((y) => y.slug === this.$route.params.pageSlug)
    );
    this.pageData = getCategory2.pages.find(
      (z) => z.slug == this.$route.params.pageSlug
    );
    this.metaTitle = this.pageData.title;
    this.metaDescription = this.pageData.description;

    document.body.classList.add("cms-detail", "active-page");
  },
  watch: {
    $route() {
      const langData2 = this[this.lang];

      let getCategory2 = langData2.pages.cms.find((x) =>
        x.pages.some((y) => y.slug === this.$route.params.pageSlug)
      );
      this.pageData = getCategory2.pages.find(
        (z) => z.slug == this.$route.params.pageSlug
      );
      this.metaTitle = this.pageData.title;
      this.metaDescription = this.pageData.description;
      // console.log(this.pageData);
    },
  },
  destroyed() {
    document.body.classList.remove("cms-detail", "active-page");
    this.metaTitle = null;
  },
};
</script>
<style>
</style>

  <!-- console.log(langData.pages.integrations.categories.pazaryerleri.hepsiburada); -->