<template>
  <main>
    <themesDetailComponents :lang="lang" :title="'pages.themes'" general="general" />
    <recommendationComponent :lang="lang" :title="'pages.themes.recommendations'" />
    <!-- <themeExamplesComponent
    :lang="lang"
    :title="'pages.themes'"
    general="general"
    ></themeExamplesComponent> -->
    <!-- <pageInnerHeaderComponent
      :lang="lang"
      :title="'pages.themes'"
    ></pageInnerHeaderComponent> -->
    <callToActionComponent :lang="lang" :title="'pages.home.callToAction'" />

  </main>
</template>
<script>
// import pageInnerHeaderComponent from "@/components/pageInnerHeader/pageInnerHeader.vue";
import themesDetailComponents from "@/components/themesDetail/themesDetail.vue";
// import themeExamplesComponent from "@/components/themeExamples/themeExamples.vue";
import recommendationComponent from "@/components/recommendation/recommendation.vue";
import callToActionComponent from "@/components/callToAction/callToAction.vue";
import tr from "@/lang/tr.js";
import en from "@/lang/en.js";
export default {
  mixins: [tr, en],
  name: "ThemesView",
  components: {
    // pageInnerHeaderComponent,
    themesDetailComponents,
    // themeExamplesComponent,
    recommendationComponent,
    callToActionComponent,
  },
  metaInfo() {
    return {
      title: this.metaTitle
        ? this.metaTitle
        : "KOBİSİ HIZLI E-TİCARET PLATFORMU",
      meta: [
        {
          name: "description",
          content: this.metaDescription ? this.metaDescription : "",
        },
        {
          property: "og:title",
          content: this.metaTitle ? this.metaTitle : "KOBİSİ HIZLI E-TİCARET PLATFORMU",
        },
        { name: "robots", content: "index,follow" },
        { property: "og:type", content: "" },
        { property: "og:title", content: " Neden Kobisi'yi tercih etmeliyim?" },
        { property: "og:description", content: "Kullanım kolaylığı, güvenlik ve hız, müşterilerimizin e-ticaret sitelerini açmak için Kobisi’yi tercih etmelerinin altında yatan nedenlerden sadece birkaçı." },
        { property: "og:image", content: "" },
        { property: "og:url", content: "kobisi.com/tasarimlar" },
        { property: "og:site_name", content: "Kobisi" },
      ],
    };
  },
  data() {
    return {
      metaDescription: null,
      metaTitle: null,
    };
  },
  props: {
    lang: String,
    title: String,
    general: String
  },
  mounted() {
    document.body.classList.add("themes", "active-page");
    this.metaTitle = this[this.lang].pages.themes.metaTags.title;
    this.metaDescription = this[this.lang].pages.themes.metaTags.description;

    // let a = this[this.lang].pages.themes.metaTags.title;

    // window.addEventListener("blur", function () {
    //   document.title = "bizi unutmayın"
    // });

    // window.addEventListener("focus", function () {
    //   document.title = a
    // });

  },
  destroyed() {
    document.body.classList.remove("themes", "active-page");
  },
};
</script>
<style>

</style>