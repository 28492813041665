<template v-if="lang && __(title)">
  <section
    :id="__(title).id"
    :class="[
      __(title).reverse && __(title).reverse == true ? 'reverse' : '',
      __(title).dark && __(title).dark == true ? 'dark' : '',
    ]"
    class="horizontal-infographic"
  >
    <div class="paralax-item shape-triangle">
      <div class="paralax-item-inner spin speed-2">
        <svg class="shape">
          <use
            xlink:href="../../assets/images/icon/shape-sprite.svg#triangle"
          />
        </svg>
      </div>
    </div>
    <div class="section-wrap">
      <div class="container">
        <div class="row" v-if="__(title).title">
          <div class="section-title cntr">
            <h2>{{ __(title).title }}</h2>
          </div>
        </div>
        <div class="row row-cols-1 row-cols-sm-2 justify-content-center" :class=" 'row-cols-lg-'+(__(title).list.length>6?'4':__(title).list.length)">
          <div
            class="horizontal-infographic-item"
            :class="[__(title).animated == true ? 'animated' : '']"
            v-for="listitem in __(title).list"
            :key="listitem.id"
          >
            <div class="shape-icon">
              <svg class="shape">
                <use
                  :xlink:href="
                    require('../../assets/images/icon/icon-sprite.svg') +
                    '#' +
                    listitem.icon
                  "
                />
              </svg>
            </div>
            <div class="info">
              <h3>{{ listitem.title }}</h3>
              <p>
                {{ listitem.shortDescription }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// Import Swiper Vue.js components
import tr from "@/lang/tr.js";
import en from "@/lang/en.js";
export default {
  mixins: [tr, en],
  name: "horizontalInfographicComponent",
  props: {
    lang: String,
    title: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="./horizontalInfographic.scss" lang="scss" scoped>
</style>
