<template  v-if="lang && __(title)">
  <section class="features">
    <div class="paralax-item shape-dotes">
      <div class="paralax-item-inner bottom speed-10">
        <svg class="shape">
          <use xlink:href="../../assets/images/icon/shape-sprite.svg#dotes" />
        </svg>
      </div>
    </div>
    <div class="paralax-item shape-zigzag">
      <div class="paralax-item-inner bottom speed-5">
        <svg class="shape">
          <use xlink:href="../../assets/images/icon/shape-sprite.svg#zigzag" />
        </svg>
      </div>
    </div>
    <div class="paralax-item shape-triangle">
      <div class="paralax-item-inner spin speed-2">
        <svg class="shape">
          <use
            xlink:href="../../assets/images/icon/shape-sprite.svg#triangle"
          />
        </svg>
      </div>
    </div>
    <div class="paralax-item shape-plus">
      <div class="paralax-item-inner bottom speed-10">
        <svg class="shape">
          <use xlink:href="../../assets/images/icon/shape-sprite.svg#plus" />
        </svg>
      </div>
    </div>
    <div class="paralax-item shape-circle">
      <div class="paralax-item-inner top spin speed-5">
        <svg class="shape">
          <use xlink:href="../../assets/images/icon/shape-sprite.svg#circle" />
        </svg>
      </div>
    </div>
    <div class="inner-wrapper">
      <div
        class="preferences-item"
        v-for="(preference, index) in __(title).list"
        :key="index"
      >
        <div class="container">
          <div class="inner-wrap">
            <div class="preferences-item-image animated">
              <img :src="preference.image" alt="" />
            </div>
            <div class="preferences-item-info animated">
              <h2>{{ preference.title }}</h2>
              <p>{{ preference.content }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// Import Swiper Vue.js components
import tr from "@/lang/tr.js";
import en from "@/lang/en.js";
export default {
  mixins: [tr, en],
  name: "generalPreferencesComponent",
  props: {
    lang: String,
    title: String,
    general: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="./generalPreferences.scss" lang="scss" scoped>
</style>
